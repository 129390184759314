import React from 'react'
import './notfound.css';

function NotfoundPage() {
  return (
    <div>
        <h1 className='notfoundpage display-1'>Page Not Found</h1>
    </div>
  )
}

export default NotfoundPage